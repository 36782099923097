import React from 'react'
import './education.css'
import UoELogo from '../../assets/uoe-logo.svg'
import MumbaiUniLogo from '../../assets/mumbaiuni-logo.svg'
import KcLogo from '../../assets/kc.svg'

const Education = () => {
  return (
    <section id='education'>
      <h2>Education</h2>

      <div className='container education__container'>
        <div className="education__cards">
          <article className="education__card">
            <div className='education__img__container'>
              <img src={UoELogo} alt="uoe-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>MSc. Social and Organisational Psychology</h3>
                  <h3 className='text-light'>University of Exeter | Exeter, UK</h3>
                </div>
                <div className='education__duration'>
                  <h3>2022 - 2023</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
          <article className="education__card">
            <div className='education__img__container'>
              <img src={MumbaiUniLogo} alt="mumbaiuni-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>Bachelor of Arts - </h3>
                  <h3>Psychology and English Literature</h3>
                  <h3 className='text-light'>Bhavans College, University of Mumbai</h3>
                  <h3 className='text-light'>Mumbai, India</h3>
                </div>
                <div className='education__duration'>
                  <h3>2018 - 2021</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
          <article className="education__card">
            <div className='education__img__container'>
              <img src={KcLogo} alt="kc-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>Bachelor of Commerce</h3>
                  <h3 className='text-light'>Kishinchand Chellaram College</h3>
                  <h3 className='text-light'>Mumbai, India</h3>
                </div>
                <div className='education__duration'>
                  <h3>2015 - 2018</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Education