import React from 'react'
import './experience.css'
import NewfoldLogo from '../../assets/newfold-logo.svg'
import BrightLogo from '../../assets/bright-logo.svg'
import BakevibesLogo from '../../assets/bakevibes-logo.png'

const Experience = () => {
  return (
    <section id='experience'>
      <h2>Work Experience</h2>

      <div className='container experience__container'>
        <div className="experience__cards">
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={BrightLogo} alt="bright-logo"/>
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Business, Operations & Marketing Internship Experience 2024</h3>
                  <h3 className='text-light'>Bright Network | London, UK</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>June 2024 - June 2024</h3>
                </div>
              </div>

              <div className='experience__description'>
                Crafted a strategic partnership proposal for British Airways in collaboration with Believe in the Invisible, showcasing expertise in raising awareness around invisible disabilities. Actively engaged in a virtual program with industry leaders, enhancing business acumen and sharpening problem-solving abilities through hands-on projects. Demonstrated strengths in strategic planning, DEI, and project coordination, with a focus on impactful awareness-raising initiatives. Skilled in document preparation, partnership development, and adept at fostering inclusive initiatives.
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={NewfoldLogo} alt="newfold-logo"/>
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Sales Associate</h3>
                  <h3 className='text-light'>Newfold Digital | Mumbai, India</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>June 2021 - June 2022</h3>
                </div>
              </div>

              <div className='experience__description'>
                Achieved recognition as the MVP for outstanding performance in November 2021, demonstrating expertise in
                product awareness by providing solutions and creating customer awareness. Actively engaged in campaigns
                for lead generation, fostering client rapport through effective coordination, and contributed to
                interface issue analysis through rigorous testing, presenting insightful reports. Proficient in
                Salesforce, Microsoft Office, and possess strong skills in sales presentations, target-driven
                approaches, and analytical problem-solving for business development.
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={BakevibesLogo} alt="bakevibes-logo"/>
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Co-founder</h3>
                  <h3 className='text-light'>Bakevibes (Currently Moonbills) | Mumbai, India</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>August 2020 - November 2020</h3>
                </div>
              </div>

              <div className='experience__description'>
                Pioneered the establishment of an online bakery, showcasing entrepreneurial prowess. Managed diverse
                clientele interactions, orders, and feedback, significantly boosting business interest and
                accountability. Spearheaded the development of promotional policies and branding strategies on social
                media (@moonbills), leveraging analytical skills for effective online presence.
              </div>

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Experience