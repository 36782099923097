import React from "react";
import { BsLinkedin } from "react-icons/bs";
// import { FaGithub } from "react-icons/fa";
// import { BsTwitter } from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/srishti-dadhich-98d/" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
      <a href="mailto:srishtidadhich@gmail.com" target="_blank" rel="noopener noreferrer">
        <SiMaildotru />
      </a>

    </div>
  );
};

export default HeaderSocials;
