import React from "react";
import CV from '../../assets/Resume_SrishtiDadhich.pdf';
import {FaDownload} from "react-icons/fa";

const CTA = () => {
  return (
    <div className="cta">
      <a href={CV} download className="btn"><FaDownload/> Get Resume</a>
      <a href="#contact" className="btn btn-primary">Let's Connect!</a>
    </div>
  );
};

export default CTA;
